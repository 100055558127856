<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" :title="dialogTitle"
               custom-class="create-edit">
      <el-card v-if="!currentId">
        <artist-search-custom :type="`business_plan`" @handleSelect="selectedArtist" style="width: 100%"/>
      </el-card>
      <br/>
      <el-card>
        <el-form ref="elForm" :model="formData" :rules="rules" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="红人昵称：" prop="nickname">
                <el-input v-model="formData.nickname" placeholder="红人昵称" :disabled="true" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="平台：" prop="platform_code">
                <PlatformSelect v-model="formData.platform_code" :default-code="formData.platform_code"
                                @change="changePlatform" :range="platformRange" :disabled="!!formData.id" clearable/>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="商务类型：" prop="business_type">
                <el-select v-model="formData.business_type" placeholder="商务类型" clearable>
                  <el-option :label="item.label" :value="item.value" v-for="(item,key) in optionMaps.business_type"
                             :key="key"></el-option>
                </el-select>
                <!--                <el-input v-model="formData.business_type" placeholder="商务类型" clearable></el-input>-->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合作形式：">
                <el-input v-model="formData.coa_mode" placeholder="合作形式" maxlength="100" show-word-limit
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="优惠/福利：">
                <el-input v-model="formData.discount" placeholder="优惠/福利" maxlength="100" show-word-limit
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报备：">
                <el-input v-model="formData.report" placeholder="报备" maxlength="200" show-word-limit
                          clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态：" prop="status">
                <el-select v-model="formData.status" placeholder="状态:锁定档期必选发布时间" clearable>
                  <el-option :label="item.label" :value="item.value" v-for="(item,key) in optionMaps.status"
                             :key="key" :disabled="item.disabled">
                    <span style="float: left">{{ item.label }}</span>
                    <span style="float: right; color: #8492a6; font-size: 8px;margin-left: 10px">{{
                        item.explain
                      }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发布时间：" prop="release_date">
                <el-date-picker
                    v-model="formData.release_date"
                    type="date"
                    format="yyyy年MM月dd日"
                    value-format="yyyy-MM-dd"
                    placeholder="选择发布时间" style="width: 100%" clearable>
                </el-date-picker>
                <!--                <el-input v-model="formData.release_time" placeholder="发布时间" clearable></el-input>-->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="内容主题：">
                <el-input v-model="formData.theme_content" placeholder="内容主题" maxlength="500" style="width: 100%"
                          show-word-limit clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <AdItemEdit ref="refAdItemEdit" :data="formData.ad_items" :can-edit="canEdit"/>
            </el-col>
          </el-row>
        </el-form>
        <el-row>
          <el-col :span="24">
            <Tips :data="tipsList"/>
          </el-col>
        </el-row>
      </el-card>

      <el-dialog
          width="30%"
          title="延期操作"
          :visible.sync="innerVisible"
          append-to-body>
        <el-form :inline="true" label-width="100px">
          <el-form-item label="延期至：">
            <el-date-picker
                v-model="delayFormData.new_date"
                type="date"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
                placeholder="选择上线日期" clearable>
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="primary" @click="delayInfo">确定延期</el-button>
        </div>
      </el-dialog>
      <div slot="footer">
        <el-row>
          <el-col :span="24">
            <el-button type="danger" @click="rejBtn" v-if="currentId" :disabled="!canEdit">
              撤单<i class="el-icon-d-arrow-right"></i>
            </el-button>

            <el-button type="warning" @click="delay" v-if="currentId" :disabled="!canEdit">
              延期<i class="el-icon-d-arrow-right"></i>
            </el-button>

            <el-button type="danger" plain @click="delBtn" v-if="currentId" :disabled="!canEdit">
              删除<i class="el-icon-delete"></i>
            </el-button>
            <el-button @click="close">取消</el-button>
            <el-button type="primary" @click="handleConfirm" :disabled="loadingCommit" :loading="loadingCommit"
                       v-if="canEdit">
              保存
            </el-button>
            <el-button type="default" :disabled="true" v-else>
              保存(无权限)
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PlatformSelect from '@/pages/platform/components/PlatformSelect'
import { mapGetters } from "vuex";
import Tips from "@/components/Tips";
import AdItemEdit from "./AdItemEdit";
import ArtistSearchCustom from "@/components/artist/ArtistSearchCustom";
export default {
  name: "CreateEdit",
  components: {
    PlatformSelect, Tips, AdItemEdit,ArtistSearchCustom
  },
  props: {
    editObj: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo']),
    dialogTitle() {
      return `商务管理-${ this.currentId ? '编辑(' + this.editObj.group_name + '：' + this.editObj.nickname + ')' : '添加' }`
    }
  },
  watch: {
    'formData.status'(val) {
      if (val === 'lock') {
        this.rules.release_date = [{ required: true, message: '锁定档期-发布日期', trigger: 'blur' }];
      } else {
        this.rules.release_date = [{ required: false, message: '锁定档期-发布日期', trigger: 'blur' }];
      }

    }
  },
  data() {
    return {
      tipsList: [
        '当已录入红人昵称、平台、商务类型、发布时间，并且状态不是拒单/撤单时，该信息在档期管理相应区块进行展示，排期内容为品牌+产品名称+内容主题（同步后可在档期管理里编辑）',
        '当商务管理选择时间在档期管理里已经有排期时，该时间显示不可选择，需要先调整当日内容发布计划后才可以进行选择',
        '当状态为延期时，发布时间中会显示原先的发布时间和延期后新的发布时间，档期管理中会自动将相应区块移动到新的发布时间区块内',
        '当状态为拒单/撤单时，档期管理会自动清空相应区块内容'
      ],
      currentId: null,
      loadingCommit: false,
      formData: {
        group_id: '',
        group_name: '',
        artist_id: '',
        nickname: '',
        platform_code: '',
        business_type: '',
        coa_mode: '',
        discount: '',
        report: '',
        status: null,
        release_date: null,
        theme_content: '',
        executor: '', brand: '', product_title: '',
        ad_items: [
          { executor: '', brand: '', product_title: '' }
        ]
      },
      ad_temp: {
        executor: '', brand: '', product_title: ''
      },
      rules: {
        nickname: [{ required: true, message: '红人昵称不能为空', trigger: 'blur' }],
        platform_code: [{ required: true, message: '平台必选', trigger: 'blur' }],
        business_type: [{ required: true, message: '商务类型必选', trigger: 'blur' }],
        status: [{ required: true, message: '状态必选', trigger: 'blur' }],
        release_date: [{ required: false, message: '锁定档期-发布日期', trigger: 'blur' }],
      },
      optionMaps: {
        status: [
          { label: '预留档期', value: 'reserved', explain: '该订单红人有意向，但还未最终确定，需要持续跟进', disabled: false },
          { label: '沟通档期', value: 'communicate', explain: '该订单已经确认，但还未最终确定发布档期', disabled: false },
          { label: '锁定档期', value: 'lock', explain: '该订单包括档期在内各类细节均已确认完毕，等待执行发布', disabled: false },
          { label: '延期', value: 'delay', explain: '该订单与客户达成一致需要更改发布时间', disabled: true },
          { label: '拒单/撤单', value: 'rejection', explain: '该订单最终未能执行，与客户沟通后撤单', disabled: true },
        ],
        business_type: [
          { label: '品宣商务', value: 'promotion' },
          { label: '挂车商务', value: 'cart' },
        ]
      },
      platformRange: [],
      artist_info: {},
      canEdit: true,
      innerVisible: false,
      delayFormData: { new_date: null },
    }
  },
  methods: {
    onOpen() {
      this.loadingCommit = false
      this.formData = {
        artist_id: '',
        nickname: '',
        executor: '',
        platform_code: '',
        brand: '',
        product_title: '',
        business_type: '',
        coa_mode: '',
        discount: '',
        report: '',
        status: null,
        release_date: null,
        theme_content: '',
        ad_items: []
      }
      this.getAInfo()
    },
    onClose() {
      this.$refs['elForm'].resetFields()
      this.$emit('refreshData')
    },
    close() {
      this.$emit('update:visible', false)
    },
    handleConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        this.loadingCommit = true
        this.saveInfo()
        setTimeout(() => {
          this.loadingCommit = false
        }, 3000)
      })
    },
    //保存
    async saveInfo() {
      //获取广告信息
      let adItems = await this.$refs['refAdItemEdit'].getAdItems()
      this.formData.ad_items = adItems
      await this.$api.saveBusinessPlan(this.formData)
      this.loadingCommit = false
      this.close()
    },
    async getAInfo() {
      this.currentId = null
      if (this.editObj.id) {
        await this.getPlatformRange(this.editObj.artist_id);
        let { info, artist_info } = await this.$api.getBusinessPlan(this.editObj.id)
        this.currentId = info.id//档期的ID
        this.formData = info
        this.artist_info = artist_info

        this.canEdit = this.calcPermission(artist_info.group_id)
      }

    },
    selectedArtist(val) {
      console.log('val', val)
      this.formData.group_id = val.group_id
      this.formData.group_name = val.group_name
      this.formData.artist_id = val.artist_id
      this.formData.nickname = val.nickname
      this.getPlatformRange(val.artist_id);
      this.canEdit = this.calcPermission(val.group_id)
    },
    changePlatform(platform_code) {
      this.formData.platform_code = platform_code
    },
    async delBtn() {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow(this.editObj)

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //删除
    async delRow(currentRow) {
      await this.$api.delBusinessPlan(currentRow.id)
      this.loadingCommit = false
      this.close()

    },
    delay() {
      this.innerVisible = true
    },
    /**
     * 延期
     * @returns {Promise<void>}
     */
    async delayInfo() {
      if (!this.delayFormData.new_date) {
        this.$notify.error('请选择延期发布的日期！！')
        return
      }

      await this.$api.delayBusinessPlan({ id: this.currentId, release_date: this.delayFormData.new_date })
      this.loadingCommit = false
      this.innerVisible = false
      this.close()
    },
    //拒单
    rejBtn() {
      this.$confirm('此操作将设置为“拒单/撤单”, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.rejRow()

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    async rejRow() {
      if (this.currentId) {
        await this.$api.rejBusinessPlan(this.currentId)
        this.loadingCommit = false
        this.close()
      }
    },

    async getPlatformRange(artist_id) {
      let { info } = await this.$api.getArtistInfo(artist_id)
      this.platformRange = []
      if (info.platforms) {
        let objs = info.platforms
        let codes = Object.keys(objs)
        for (let p in codes) {
          let code = codes[p]
          let val = objs[code]
          if (val === 'Y')
            this.platformRange.push(code)
        }
      }
    },
    //计算权限
    calcPermission(currentGroupId) {
      //录入权限
      let hasPermission = this.userPermissions.indexOf('business_manage_create') > -1
      //范围权限
      let rangePermission = false

      // let currentGroupId = this.searchCondition.group_id
      //数据范围
      //有全公司 数据权限
      if (this.userPermissions.indexOf('business_manage_data_company') > -1) {
        rangePermission = true

      } else if (this.userPermissions.indexOf('business_manage_data_dpt') > -1) {
        // 当前选中的组在本部及其组，则有权限修改
        let dptRange = this.userDptInfo.dpt_range
        rangePermission = dptRange ? dptRange.indexOf(currentGroupId) > -1 : false

      } else if (this.userPermissions.indexOf('business_manage_data_group') > -1) {
        // 当前选中的组在个人归属的组（一个人可在多组），则有权限修改
        let dpt_ids = this.userDptInfo.my_dpt_range ? this.userDptInfo.my_dpt_range : []
        rangePermission = dpt_ids ? dpt_ids.indexOf(currentGroupId) > -1 : false

      } else {
        rangePermission = false
      }

      // console.log(`组：${ currentGroupId }, hasPermission:${ hasPermission },rangePermission:${ rangePermission }`)
      return hasPermission && rangePermission
    },
    changeStatus() {
      // alert('1212')
      console.log('changeStatus', this.formData)
    }
  }
}
</script>

<style>
.create-edit {
  min-width: 900px !important;
}
</style>
