<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" @opened="onOpened" @close="onClose" :title="dialogTitle" width="1100px">
    <el-row v-if="!this.searchCondition.info_id">
      <el-col :span="24">
        <el-form :inline="true">
          <el-form-item label="红人检索：">
            <artist-search @handleSelect="chooseArtist"/>
          </el-form-item>
          <el-form-item label="类型：">
            <el-select v-model="searchCondition.op_type" @change="search" clearable>
              <el-option label="添加" value="add">添加</el-option>
              <el-option label="修改" value="edit">修改</el-option>
              <el-option label="删除" value="del">删除</el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="initList"><i class="el-icon-search"></i>搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <ApeTable ref="apeTable" :data="dataList" :columns="columns" :loading="loadingStatus" :pagingData="pagingData"
              @switchPaging="switchPaging" highlight-current-row>
    </ApeTable>
  </el-dialog>
</template>

<script>
import ApeTable from "@/components/ApeTable";
import ArtistSearch from "@/components/artist/ArtistSearch";

export default {
  name: "BusinessPlanLog",
  inheritAttrs: false,
  components: {
    ApeTable, ArtistSearch
  },
  props: {
    infoId: {
      type: [String, Number],
      default() {
        return {}
      }
    }
    // searchCondition: {
    //   type: Object,
    //   default() {
    //     return {}
    //   }
    // }
  },
  data() {
    return {
      loadingStatus: false,
      dataList: [],
      columns: [
        {
          title: '日志内容',
          value: 'msg',
          width: 600,
          header_align: 'center',
          align: 'left'
        },
        {
          title: '操作',
          value: 'op_type_alias',
          width: 100,
          header_align: 'center',
          align: 'center'
        },
        {
          title: '操作时间',
          value: 'created_at',
          width: 120,
          header_align: 'center',
          align: 'center'
        },
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0,
      },
      searchCondition: { op_type: null, info_id: null, artist_id: null }
    }
  },
  computed: {
    dialogTitle() {
      return `操作日志${this.searchCondition.info_id?'(ID:'+this.searchCondition.info_id+')':''}`;
    }
  },
  watch: {
    infoId(val) {
      this.searchCondition.info_id = val
    }
  },
  created() {
  },
  mounted() {
    this.searchCondition.artist_id = null
  },
  methods: {
    //检索红人
    async chooseArtist(item) {
      if (item) {
        this.searchCondition.artist_id = item.id
      } else {
        this.searchCondition.artist_id = null
      }
      this.search()
    },
    search() {
      this.$refs['apeTable'].handleCurrentChange(1)
    },
    // 切换页码操作
    async switchPaging() {
      await this.initList()
    },
    async initList(type) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, pagingInfo)
      let { list, pages } = await this.$api.getBusinessPlanLog(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total

      this.pagingData.offset = pages.offset
      this.loadingStatus = false
    },
    handleSearchCondition() {
      let condition = {}
      this.conditionStr = '';

      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.info_id) {
        condition.info_id = this.searchCondition.info_id
      }
      if (this.searchCondition.op_type) {
        condition.op_type = this.searchCondition.op_type
      }

      return condition
    },
    onOpened() {
      this.switchPaging()
    },
    onClose() {
      this.dataList = []
      // this.$refs['elForm'].resetFields()
    },
  }
}

</script>

<style scoped>

</style>
