<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" :title="dialogTitle">
      <div class="default-table">
        <el-table :data="dataList" style="width: 100%" border>
          <el-table-column label="执行环节" prop="step_title" align="center">
            <template slot-scope="scope">
              <span :title="scope.row.step">{{ scope.row.step_title }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否完成" prop="is_done" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.is_done" true-label="Y" false-label="N"
                           @change="saveCheck(scope.row)" :disabled="!canEdit"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="备注信息" prop="remark" align="center">
            <template slot-scope="scope">
              <editable-cell :show-input="scope.row.editMode" v-model="scope.row.remark"
                             :is-input-number="false" @change="changeVal(scope.row,`remark`)" v-if="canEdit">
              <span slot="content">
                <span>{{ scope.row.remark }}</span><i class="el-icon-edit"></i>
              </span>
              </editable-cell>
              <span v-else>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import EditableCell from "@/components/EditableCell";
import { mapGetters } from "vuex";

export default {
  name: "RateProgress",
  components: {
    EditableCell
  },
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo']),
    dialogTitle() {
      return `执行进度：${ this.editObj.nickname }/${ this.editObj.executor }/${ this.editObj.platform_name }/${ this.editObj.product_title }`
    }
  },

  props: {
    editObj: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      dataList: [
        // { step: '平台接单', is_done: 'N', remark: '' },
        // { step: 'brief', is_done: 'N', remark: '' },
        // { step: '收到样品', is_done: 'N', remark: '' },
        // { step: '脚本', is_done: 'N', remark: '' },
        // { step: '拍摄', is_done: 'N', remark: '' },
        // { step: '素材审核', is_done: 'N', remark: '' },
        // { step: '剪辑', is_done: 'N', remark: '' },
        // { step: '初稿审核', is_done: 'N', remark: '' },
        // { step: '封面', is_done: 'N', remark: '' },
        // { step: '文案', is_done: 'N', remark: '' },
        // { step: '定稿', is_done: 'N', remark: '' },
        // { step: '发布', is_done: 'N', remark: '' },
      ],
      canEdit: false,
    }
  },
  methods: {
    onOpen() {
      this.getAInfo()
    },
    onClose() {
      // this.$refs['elForm'].resetFields()
      this.$emit('refreshData')
    },
    close() {
      this.$emit('update:visible', false)
    },
    changeVal(row, field) {
      let postData = { plan_id: this.editObj.id, step: row.step }
      postData[field] = row[field]
      this.$api.saveBusinessPlanStep(postData)
    },
    saveCheck(row) {
      let postData = { plan_id: this.editObj.id, step: row.step, is_done: row.is_done }
      this.$api.saveBusinessPlanStep(postData)
    },
    async getAInfo() {
      let { list, artist_info } = await this.$api.getBusinessPlanStep({ plan_id: this.editObj.id })
      this.canEdit = this.calcPermission(artist_info.group_id)
      this.$nextTick(() => {
        this.dataList = list
      })
      // this.dataList = this.dataList.map(( item ) => {
      //   //
      //   let row = data_map[item.step] ? data_map[item.step] : {};
      //   item.is_done = row.is_done ? row.is_done : 'N'
      //   item.remark = row.remark ? row.remark : ''
      //   return item
      // })
    },
    //计算权限
    calcPermission(currentGroupId) {
      //录入权限
      let hasPermission = this.userPermissions.indexOf('business_manage_create') > -1
      //范围权限
      let rangePermission = false

      // let currentGroupId = this.searchCondition.group_id
      //数据范围
      //有全公司 数据权限
      if (this.userPermissions.indexOf('business_manage_data_company') > -1) {
        rangePermission = true

      } else if (this.userPermissions.indexOf('business_manage_data_dpt') > -1) {
        // 当前选中的组在本部及其组，则有权限修改
        let dptRange = this.userDptInfo.dpt_range
        rangePermission = dptRange ? dptRange.indexOf(currentGroupId) > -1 : false

      } else if (this.userPermissions.indexOf('business_manage_data_group') > -1) {
        // 当前选中的组在个人归属的组（一个人可在多组），则有权限修改
        let dpt_ids = this.userDptInfo.my_dpt_range ? this.userDptInfo.my_dpt_range : []
        rangePermission = dpt_ids ? dpt_ids.indexOf(currentGroupId) > -1 : false

      } else {
        rangePermission = false
      }

      // console.log(`组：${ currentGroupId }, hasPermission:${ hasPermission },rangePermission:${ rangePermission }`)
      return hasPermission && rangePermission
    },
  }
}
</script>

<style scoped>

</style>
