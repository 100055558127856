<template>
  <div class="ad-info">
    <el-divider content-position="left">广告信息</el-divider>
    <el-row class="default-table">
      <el-col :span="24">
        <el-table :data="tableData" style="width: 100%" size="mini" border>
          <el-table-column align="center" label="AE" prop="executor" min-width="120"></el-table-column>
          <el-table-column align="center" label="品牌" prop="brand" min-width="160"></el-table-column>
          <el-table-column align="center" label="产品名称" prop="product_title" min-width="360"></el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template slot-scope="scope">
              <el-link type="danger" @click="handleDelete(scope.$index, scope.row.id)" v-if="canEdit"><i
                  class="el-icon-delete"></i> 移除
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <br/>
    <el-row :gutter="15">
      <el-col :span="24">
        <el-form ref="elAdForm" :inline="true" size="mini" :model="formData" :label-position="`right`"
                 label-width="100px">
          <el-form-item label="">
            <el-input v-model="formData.executor" placeholder="AE" style="width: 150px" maxlength="50" show-word-limit
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="formData.brand" placeholder="品牌" style="width: 180px" maxlength="100" show-word-limit
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="formData.product_title" placeholder="产品名称" style="width: 360px" maxlength="100"
                      show-word-limit
                      clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-plus" @click="handleAdd" :disabled="!canEdit" circle></el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "AdItemEdit",
  data() {
    return {
      formData: {
        executor: null, brand: null, product_title: null
      },
      tableData: [],
      is_show: false

    }
  },
  props: {
    canEdit: {
      type: Boolean,
      default() {
        return true;
      }
    },
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        this.initTableData(val)
      }
    }
  },
  methods: {
    handleAdd() {
      this.tableData.push({ ...this.formData })
      this.$refs['elAdForm'].resetFields()
      this.formData = { executor: null, brand: null, product_title: null }
    },
    handleDelete(index, id) {
      if (id) {
        //真实删除
        this.$confirm('此操作将移除该广告信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(id)
          this.tableData.splice(index, 1);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      } else {
        this.tableData.splice(index, 1);
      }

      // this.$emit('currentInfo', this.tableData)
    },
    delRow(id) {
      this.$api.delBusinessPlanAd(id)
    },
    initTableData(val) {
      this.tableData = [...val]

    },
    //获取广告信息
    getAdItems() {
      return this.tableData
    }
  }
}
</script>

<style scoped>
.ad-info {
  padding: 10px 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(248, 248, 248, 1)
}
</style>
